
import { Link } from "gatsby";
import React from "react";
import { removeHost } from "../../../utils/functions";
import "./style.scss";

const AccoutNavigation = ({ navigation, handleLogout }) => {
  const { welcome, orders, logout, details, addresses, subscription, download } = navigation;

  return (
    <section className="accountNavigation">
      <nav>
        <ul>
          <li className="accountNavigation__item">
            <Link to={removeHost(welcome.url)} >{welcome.title}</Link>
          </li>
          <li className="accountNavigation__item">
            <Link to={removeHost(orders.url)} >{orders.title}</Link>
          </li>
          <li className="accountNavigation__item">
            <Link to={removeHost(details.url)} >{details.title}</Link>
          </li>
          <li className="accountNavigation__item">
            <Link to={removeHost(addresses.url)} >{addresses.title}</Link>
          </li>
          <li className="accountNavigation__item">
            <Link to={removeHost(download.url)} >{download.title}</Link>
          </li>
          <li className="accountNavigation__item">
            <Link to={removeHost(subscription.url)} >{subscription.title}</Link>
          </li>
          <li className="accountNavigation__item">
            <a href="#" onClick={(e) => { e.preventDefault(); handleLogout() }}>{logout}</a>
          </li>
        </ul>
      </nav>
    </section>
  );
};

export default AccoutNavigation;
