import { gql } from "@apollo/client";
const GET_CUSTOMER_ORDER = gql`
    query GET_CUSTOMER_ORDER( $customerId: Int ) {
      customer( customerId: $customerId ) {
        orders {
          edges{
            node {
              id
              orderKey
              orderNumber
              date
              status
              total
              customerNote
              paymentMethodTitle
              lineItems {
                edges {
                  node {
                    product {
                      node{
                        name
                        id
                      }
                    }
                  }
                }
              }
              
            }
          }
        }
        subscriptionItems {
          products {
            id_product
            name
            link
            price
            total_price
            quantity
          }
          
          order_id
          active
          start_payment
          next_payment
          end_payment
          status_payment
          total_price
          active
          shipping_name
          shipping_total
          shipping_address
        }
        downloadableItems {
          nodes {
            name
            product {
              image {
                id
                altText
                sourceUrl
              }
            }
            url
          }
        }
      }
    }
`;

export default GET_CUSTOMER_ORDER;

