import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import GET_CUSTOMER_ORDER from "../../../queries/get-customer-orders"
import { isEmpty } from "lodash"
import Spin from "../../spin/Spin"
import "./style.scss"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Dowload = props => {
  // console.log(props)
  const id = props.authData.user.databaseId
  const { title, textButton,noDownloadItems } = props.dowload
  console.log(props)
  const [loading, setLoading] = useState(true)
  const [dowload, setDowload] = useState(true)
  // Get Cart Data.
  useQuery(GET_CUSTOMER_ORDER, {
    variables: {
      customerId: id,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
    onCompleted: data => {
      setLoading(false)
      console.log(data.customer)

      setDowload(data.customer.downloadableItems)
    },
    onError: errors => {
      // if (errors) {
      //   setRequestError(error?.graphQLErrors?.[0]?.message ?? '');
      // }
    },
  })

  // if (isEmpty(data)) {
  //   return (
  //     <>
  //       <h1>{title}</h1>
  //       <div className="myAccount__spin">
  //         <Spin />
  //       </div>
  //     </>
  //   );
  // }
  return (
    <>
      <h1>{title}</h1>
      <div className="content">
        {loading ? (
          <div className="myAccount__spin">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(dowload.nodes) ? (
              <div className="myAccountDowload grid grid--4">
                {dowload.nodes.map((item, index) => {
                  return (
                    <div
                      className="myAccountDowload__item"
                      key={`download__${index}`}
                    >
                      <a
                        href={item.url}
                        target="_blank"
                        className="myAccountDowload__item__img"
                      >
                        <figure>
                          <LazyLoadImage
                            alt={
                              item.product.image.altText
                                ? item.product.image.altText
                                : ""
                            }
                            src={item.product.image.sourceUrl} // use normal <img> attributes as props
                            effect="blur"
                            height="250"
                            width="250"
                          />
                        </figure>
                      </a>
                      <p className="myAccountDowload__item__name">
                        {item.name}
                      </p>
                      <div className="myAccountDowload__item__button">
                        <a
                          className="btn"
                          href={item.url}
                          target="_blank"
                        >
                          {textButton}
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="content">
                <div dangerouslySetInnerHTML={{ __html: noDownloadItems }}></div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default Dowload
